var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cls from './Button.module.css';
import classNames from 'classnames';
import CloseIcon from 'shared/assets/icons/close-cross.svg';
export var ButtonVariants;
(function (ButtonVariants) {
    ButtonVariants["ACTION"] = "action";
    ButtonVariants["ACTION_BLUE"] = "actionBlue";
    ButtonVariants["ACTION_RED"] = "actionRed";
    ButtonVariants["OUTLINED"] = "outlined";
    ButtonVariants["TEXT"] = "text";
    ButtonVariants["CLOSE"] = "close";
    ButtonVariants["OPTION"] = "option";
})(ButtonVariants || (ButtonVariants = {}));
export var Button = function (props) {
    var _a;
    var text = props.text, classNamesProps = props.classNamesProps, disabled = props.disabled, onClick = props.onClick, variant = props.variant, type = props.type, endIcon = props.endIcon, id = props.id, rest = __rest(props, ["text", "classNamesProps", "disabled", "onClick", "variant", "type", "endIcon", "id"]);
    var classes = classNames(cls.button, cls[variant], (_a = {}, _a[cls.disabled] = disabled, _a), classNamesProps);
    return (_jsxs("button", __assign({ id: id, onClick: onClick, className: classes, disabled: disabled, type: type }, rest, { children: [variant !== ButtonVariants.CLOSE && text, variant === ButtonVariants.CLOSE && _jsx(CloseIcon, {}), endIcon && _jsx("div", __assign({ className: cls.endIcon }, { children: endIcon }))] })));
};
