var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Card from 'shared/components/Card/Card';
import cls from './UsersMobileTable.module.css';
import Loader from 'shared/components/Loader/Loader';
import EditableText from 'shared/components/EditableText/EditableText';
import EditableSelect from 'shared/components/EditableSelect/EditableSelect';
import { generateUserRoles, getUserRole } from '../helpers';
import { DeleteUserButton } from '../components/DeleteUserButton';
import classNames from 'classnames';
var UsersScreenMobileTable = function (_a) {
    var _b;
    var isEditing = _a.isEditing, onChange = _a.onChange, values = _a.values, data = _a.data;
    var loading = data.loading, error = data.error, rest = __rest(data, ["loading", "error"]);
    var users = (_b = rest.data) === null || _b === void 0 ? void 0 : _b.usersByOrganization;
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx("div", { children: "Error fetching users. Try again later." });
    if (!(users === null || users === void 0 ? void 0 : users.length))
        return _jsx("div", { children: "No users!" });
    return (_jsx(_Fragment, { children: users.map(function (user) {
            var _a, _b, _c, _d, _e;
            return (_jsxs(Card, __assign({ className: cls.user_box }, { children: [_jsxs("div", __assign({ className: cls.user_box_top }, { children: [_jsxs("div", __assign({ className: cls.user_name }, { children: [_jsx("p", __assign({ className: cls.user_box_label }, { children: "Name" })), isEditing ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: cls.user_name_input }, { children: _jsx(EditableText, { id: "firstName", inputClassName: classNames(cls.textInput, cls.inputWrapper), edit: isEditing, value: (_a = values[user.id]) === null || _a === void 0 ? void 0 : _a.firstName, onChange: function (field, value) {
                                                        return onChange(user.id, field, value);
                                                    } }, "0") })), _jsx("div", __assign({ className: cls.user_name_input }, { children: _jsx(EditableText, { id: "lastName", inputClassName: classNames(cls.textInput, cls.inputWrapper), edit: isEditing, value: (_b = values[user.id]) === null || _b === void 0 ? void 0 : _b.lastName, onChange: function (field, value) {
                                                        return onChange(user.id, field, value);
                                                    } }, "0") }))] })) : (_jsxs("p", { children: [user.firstName, " ", _jsx("b", { children: user.lastName })] }))] })), _jsxs("div", __assign({ className: cls.user_role }, { children: [_jsx("p", __assign({ className: cls.user_box_label }, { children: "Role" })), isEditing ? (_jsx(EditableSelect, { id: "role", value: (_c = values[user.id]) === null || _c === void 0 ? void 0 : _c.role, edit: isEditing, onChange: function (id, value) {
                                            return onChange(user.id, id, value);
                                        }, options: generateUserRoles(user), inputClassName: classNames(cls.inputWrapper, cls.selectInput, 'user_mobile_select_input') }, "3")) : (_jsx("div", __assign({ className: cls.user_badge }, { children: _jsx("p", { children: ((_d = getUserRole(user)) === null || _d === void 0 ? void 0 : _d.label) || '' }) })))] })), _jsxs("div", __assign({ className: cls.user_status }, { children: [_jsx("p", __assign({ className: cls.user_box_label }, { children: "Status" })), _jsx("div", __assign({ className: user.active ? cls.active : cls.inactive }, { children: _jsx("p", { children: user.active ? 'Active' : 'Inactive' }) }))] })), _jsxs("div", __assign({ className: cls.user_action }, { children: [_jsx("p", __assign({ className: cls.user_box_label }, { children: "Action" })), _jsx(DeleteUserButton, { user: user })] }))] })), _jsxs("div", __assign({ className: cls.user_box_bottom }, { children: [_jsxs("div", __assign({ className: cls.user_email }, { children: [_jsx("p", __assign({ className: cls.user_box_label }, { children: "Email" })), _jsx("a", __assign({ href: "mailto:".concat(user.email), title: user.email }, { children: user.email }))] })), _jsxs("div", __assign({ className: cls.user_invoice }, { children: [_jsx("p", __assign({ className: cls.user_box_label }, { children: "Invoice" })), isEditing ? (_jsx(EditableSelect, { id: "invoices", value: ((_e = values[user.id]) === null || _e === void 0 ? void 0 : _e.invoices) ? 'yes' : 'no', edit: isEditing, onChange: function (key, value) {
                                            return onChange(user.id, key, value);
                                        }, options: [
                                            { value: 'yes', label: 'Yes' },
                                            { value: 'no', label: 'No' },
                                        ], inputClassName: classNames(cls.inputWrapper, cls.selectInput, 'user_mobile_select_input') }, "4")) : (_jsx("p", { children: user.invoices ? 'Yes' : 'No' }))] }))] }))] }), user.id));
        }) }));
};
export default UsersScreenMobileTable;
