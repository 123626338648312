import { UserRoles } from 'app/interfaces/User';
import { useUserContext } from 'app/providers/UserProvider/user-context';
export var generateUserRoles = function (currentUser) {
    var user = useUserContext().user;
    var rolesSelectOptions = [];
    if ((user === null || user === void 0 ? void 0 : user.role) === UserRoles.SUPER_ADMIN) {
        rolesSelectOptions.push({
            value: UserRoles.SUPER_ADMIN,
            label: 'Super Admin',
        });
        if ((user === null || user === void 0 ? void 0 : user.id) !== currentUser.id) {
            rolesSelectOptions.push({
                value: UserRoles.ADMIN,
                label: 'Admin',
            });
            rolesSelectOptions.push({
                value: UserRoles.USER,
                label: 'User',
            });
        }
    }
    else {
        if ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.role) === UserRoles.SUPER_ADMIN) {
            rolesSelectOptions.push({
                value: UserRoles.SUPER_ADMIN,
                label: 'Super Admin',
            });
        }
        else if ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) === (user === null || user === void 0 ? void 0 : user.id)) {
            rolesSelectOptions.push({
                value: UserRoles.ADMIN,
                label: 'Admin',
            });
        }
        else {
            rolesSelectOptions.push({
                value: UserRoles.ADMIN,
                label: 'Admin',
            });
            rolesSelectOptions.push({
                value: UserRoles.USER,
                label: 'User',
            });
        }
    }
    return rolesSelectOptions;
};
export var getUserRole = function (user) {
    if (!user)
        return null;
    return generateUserRoles(user).find(function (_a) {
        var value = _a.value;
        return value === user.role;
    });
};
export var isAdminOrSuperAdmin = function (currentUser) {
    var user = useUserContext().user;
    if (!currentUser || !user)
        return false;
    var isAdmin = (user === null || user === void 0 ? void 0 : user.role) === UserRoles.ADMIN;
    var isSuperAdmin = (user === null || user === void 0 ? void 0 : user.role) === UserRoles.SUPER_ADMIN;
    return (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) === (user === null || user === void 0 ? void 0 : user.id) || (isAdmin && isSuperAdmin);
};
