var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from 'antd';
import { useMemo } from 'react';
import cls from './SelectInput.module.css';
import clx from 'classnames';
import { Controller, } from 'react-hook-form';
var SelectInput = function (_a) {
    var label = _a.label, options = _a.options, value = _a.value, onChange = _a.onChange, className = _a.className, inputClassName = _a.inputClassName, labelClassName = _a.labelClassName, id = _a.id, disabled = _a.disabled, error = _a.error, size = _a.size, control = _a.control, inputProps = __rest(_a, ["label", "options", "value", "onChange", "className", "inputClassName", "labelClassName", "id", "disabled", "error", "size", "control"]);
    var renderWithControl = (_jsx(Controller, __assign({ control: control, name: inputProps.name }, (inputProps.rules ? { rules: inputProps.rules } : {}), { render: function (_a) {
            var field = _a.field, rest = __rest(_a, ["field"]);
            var error = rest.formState.errors[inputProps.name];
            return renderField(field, error);
        } })));
    var parsedOptions = useMemo(function () {
        return options.map(function (_a) {
            var value = _a.value, label = _a.label;
            return ({
                value: value,
                label: _jsx("span", { children: label }),
            });
        });
    }, [options]);
    var renderField = function (field, fieldError) {
        var _a;
        if (field === void 0) { field = {}; }
        if (fieldError === void 0) { fieldError = {}; }
        var errorObj = fieldError;
        var hasError = error || (errorObj === null || errorObj === void 0 ? void 0 : errorObj.message);
        var fieldObj = field;
        return (_jsxs("div", __assign({ className: clx([
                'select_container',
                cls.container,
                className,
                disabled ? cls.disable_select : '',
            ]), id: id }, { children: [label ? (_jsx("label", __assign({ className: clx([
                        'select_label',
                        cls.label,
                        labelClassName,
                    ]) }, { children: label }))) : null, _jsx(Select, __assign({}, field, { className: clx([
                        cls.select,
                        inputClassName,
                        size === 'sm' ? "".concat(cls.sm_select, " sm_select") : '',
                        size === 'xs' ? "".concat(cls.xs_select, " xs_select") : '',
                    ], (_a = {},
                        _a[cls.error] = error,
                        _a)), getPopupContainer: function (trigger) {
                        return trigger.parentElement || document.body;
                    }, options: parsedOptions, popupClassName: "".concat(cls.popup, " select_popup"), suffixIcon: _jsx("div", { className: "".concat(cls.suffix, " suffix_icon") }), value: control ? fieldObj.value : value, onChange: function (value) {
                        if (!control && onChange)
                            return onChange(value);
                        fieldObj.onChange(value);
                    }, disabled: disabled })), hasError ? (_jsx("div", __assign({ className: cls.errorMessage }, { children: error || (errorObj === null || errorObj === void 0 ? void 0 : errorObj.message) }))) : null] })));
    };
    return control ? renderWithControl : renderField();
};
export default SelectInput;
