var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Modal } from 'antd';
import cls from './InviteUserModal.module.css';
import TextInput from 'shared/components/TextInput/TextInput';
import SelectInput from 'shared/components/SelectInput/SelectInput';
import { Button, ButtonVariants } from 'shared/components';
import { gql, useMutation } from '@apollo/client';
import { useBannerContext } from 'app/providers/BannerProvider/banner-context';
var BULK_SEND_INVITES = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation BulkSendInvites($input: [CreateInviteInput!]!) {\n        bulkSendInvites(input: $input) {\n            success\n            invites {\n                id\n            }\n            errors {\n                email\n                message\n            }\n        }\n    }\n"], ["\n    mutation BulkSendInvites($input: [CreateInviteInput!]!) {\n        bulkSendInvites(input: $input) {\n            success\n            invites {\n                id\n            }\n            errors {\n                email\n                message\n            }\n        }\n    }\n"])));
var InviteUserModal = function (_a) {
    var organizationName = _a.organizationName, organizationId = _a.organizationId, inviterId = _a.inviterId, open = _a.open, onClose = _a.onClose, refetchUsers = _a.refetchUsers;
    var usersInitialState = function () { return [
        { email: '', role: 'admin', invoices: true, error: '' },
        { email: '', role: 'admin', invoices: true, error: '' },
    ]; };
    var _b = useState(usersInitialState()), users = _b[0], setUsers = _b[1];
    var bulkSendInvitesMutation = useMutation(BULK_SEND_INVITES)[0];
    var show = useBannerContext().show;
    var onAddPersonClick = function () {
        setUsers(__spreadArray(__spreadArray([], users, true), [
            { email: '', role: 'admin', invoices: true, error: '' },
        ], false));
    };
    var onCancel = function () {
        setUsers(usersInitialState());
        onClose();
    };
    var onConfirm = function () { return __awaiter(void 0, void 0, void 0, function () {
        var emailRegex, validated, input, response_1, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!users.some(function (_a) {
                        var email = _a.email;
                        return !!email;
                    })) {
                        return [2 /*return*/];
                    }
                    emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    validated = users.map(function (user) {
                        if (user.email && !emailRegex.test(user.email)) {
                            return __assign(__assign({}, user), { error: 'Invalid email.' });
                        }
                        return user;
                    });
                    if (validated.some(function (_a) {
                        var error = _a.error;
                        return !!error;
                    })) {
                        setUsers(validated);
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    input = validated
                        .filter(function (_a) {
                        var email = _a.email;
                        return !!email;
                    })
                        .map(function (_a) {
                        var email = _a.email, role = _a.role, invoices = _a.invoices;
                        return ({
                            organizationId: organizationId,
                            inviterId: inviterId,
                            email: email,
                            role: role,
                            invoices: invoices,
                        });
                    });
                    return [4 /*yield*/, bulkSendInvitesMutation({
                            variables: {
                                input: input,
                            },
                        })];
                case 2:
                    response_1 = _b.sent();
                    if (!((_a = response_1.data) === null || _a === void 0 ? void 0 : _a.bulkSendInvites.success)) {
                        setUsers(users.map(function (user) {
                            var _a, _b;
                            var error = (_b = (_a = response_1.data) === null || _a === void 0 ? void 0 : _a.bulkSendInvites.errors.find(function (_a) {
                                var email = _a.email;
                                return email === user.email;
                            })) === null || _b === void 0 ? void 0 : _b.message;
                            if (error) {
                                return __assign(__assign({}, user), { error: error });
                            }
                            return user;
                        }));
                    }
                    else {
                        setUsers([
                            { email: '', role: 'admin', invoices: true, error: '' },
                            { email: '', role: 'admin', invoices: true, error: '' },
                        ]);
                        refetchUsers();
                        onCancel();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    show(e_1.message);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Modal, __assign({ open: open, classNames: {
            content: cls.container,
            body: cls.body,
        }, wrapClassName: cls.modal_wrapper, centered: true, footer: null, maskClosable: true, getContainer: function () {
            return document.getElementById('app-layout') ||
                document.body;
        }, onCancel: onCancel }, { children: [_jsx("h2", __assign({ className: cls.title }, { children: "Invite New User" })), _jsx("span", __assign({ className: cls.subtitle }, { children: "Invite others. Only Admins can manage users" })), _jsxs("div", __assign({ className: cls.companyName }, { children: ["Company: ", _jsx("span", { children: organizationName })] })), _jsx("div", __assign({ className: cls.inputs }, { children: users.map(function (user, idx) { return (_jsxs("div", __assign({ className: cls.inputRow }, { children: [_jsx(TextInput, { label: "Work Email", containerClassName: cls.textInput, placeholder: "", value: users[idx].email, onChange: function (e) {
                                var newUsers = __spreadArray([], users, true);
                                newUsers[idx] = __assign(__assign({}, users[idx]), { email: e.target.value, error: '' });
                                setUsers(newUsers);
                            }, error: user.error }), _jsx(SelectInput, { label: "Role", className: cls.selectInput, labelClassName: cls.selectInputLabel, options: [
                                { value: 'admin', label: 'Admin' },
                                { value: 'user', label: 'User' },
                            ], value: user.role, onChange: function (value) {
                                var newUsers = __spreadArray([], users, true);
                                newUsers[idx] = __assign(__assign({}, users[idx]), { role: value });
                                setUsers(newUsers);
                            } }), _jsx(SelectInput, { label: "Invoice", className: cls.selectInput, labelClassName: cls.selectInputLabel, options: [
                                { value: 'yes', label: 'Yes' },
                                { value: 'no', label: 'No' },
                            ], value: user.invoices ? 'yes' : 'no', onChange: function (value) {
                                var newUsers = __spreadArray([], users, true);
                                newUsers[idx] = __assign(__assign({}, users[idx]), { invoices: value === 'yes' });
                                setUsers(newUsers);
                            } })] }), idx)); }) })), _jsx("div", __assign({ className: cls.modal_bottom_add_btn }, { children: _jsx(Button, { type: "button", text: "+Add Person", variant: ButtonVariants.ACTION, classNamesProps: cls.addPersonBtn, onClick: onAddPersonClick }) })), _jsxs("div", __assign({ className: cls.actionButtons }, { children: [_jsx(Button, { type: "button", text: "Cancel", variant: ButtonVariants.ACTION, classNamesProps: cls.cancel, onClick: onCancel }), _jsx(Button, { type: "button", text: "Send Invites", variant: ButtonVariants.ACTION, classNamesProps: cls.send, onClick: onConfirm })] }))] })));
};
export default InviteUserModal;
var templateObject_1;
