var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cls from './Loader.module.css';
import clx from 'classnames';
var Loader = function (_a) {
    var _b;
    var className = _a.className, _c = _a.small, small = _c === void 0 ? false : _c;
    return (_jsx("div", __assign({ className: clx(cls.container, className) }, { children: _jsxs("div", __assign({ className: clx((_b = {},
                _b[cls.common] = true,
                _b[cls['lds-ring']] = !small,
                _b[cls.small] = small,
                _b)) }, { children: [_jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {})] })) })));
};
export default Loader;
